import styled from "styled-components";
import { Box } from "../Box";
import Container from "../Layouts/Container";
import { PageHeaderProps } from "./types";

const Outer = styled(Box)<{ background?: string }>`
  background: ${({ theme, background }) => background || theme.colors.gradientBubblegum};
  //  margin-top: calc((68px + 31px) * -1);
  margin-top: -68px;
  height: 68px;
  // margin-left: calc((100vw - 240px - 64px - 1060px) * -1);
  margin-left: calc(((((100vw - 240px) - 1060px) / 2) * -1) + 32px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0) !important;
  margin-right: 300px;
  @media (max-width: 1420px) {
    margin-left: calc((60px * -1) + 32px) !important;
  }
  @media (max-width: 1067px) {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0 !important;
    text-align: center;
    height: auto;
    h1 {
      text-align: left;
      margin-left: 0;
      margin-right: auto;
    }
  }
  > div:nth-child(1) {
    @media (min-width: 1068px) {
      margin-left: 0;
      padding: 0;
    }
  }
  h1 {
    font-size: 30px;
    line-height: 30px;
    // color: ${({ theme }) => theme.colors.textBlack300};
    color: #2E0015;
    font-weight: 500;
    height: 40px;
    // margin-bottom: 24px;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  @media (max-width: 1067px) {
    h1 {
      margin-left: 0;
      margin-right: auto;
      margin-bottom: 0;
    }
  }
  h2 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
  h2:nth-child(2) {
    margin-top: 24px;
    font-weight: 500;
  }
  h2:nth-child(3) {
    font-weight: 500;
  }
`;

const Inner = styled(Container)`
  // padding-top: 32px;
  // padding-bottom: 32px;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
`;

const PageHeader: React.FC<React.PropsWithChildren<PageHeaderProps>> = ({ background, children, ...props }) => (
  <Outer background={background} {...props}>
    <Inner>{children}</Inner>
  </Outer>
);

export default PageHeader;
